import { Suspense, lazy, ElementType } from "react";
// components
import LoadingScreen from "../components/loadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------
export const OnBoardingTools = Loadable(
  lazy(() => import("../pages/OnBoarding/OnBoardingMain"))
);
export const Main = Loadable(lazy(() => import("../pages/user/Main")));
export const BusinessReferenceNumber = Loadable(
  lazy(() => import("../pages/user/Business/BusinessReferenceNumber"))
);
export const BusinessNetworkTools = Loadable(
  lazy(() => import("../pages/businessNetwork/BusinessNetworkTools"))
);
export const PaymentMain = Loadable(
  lazy(() => import("../pages/PaymentTools/Fees/PaymentMain"))
);
export const DurationMain = Loadable(
  lazy(() => import("../pages/PaymentTools/Duration/DurationMain"))
);
export const SetPartnerCommission = Loadable(
  lazy(() => import("../pages/Commission/SetPartnerCommission"))
);
export const InviteLogs = Loadable(
  lazy(() => import("../pages/Commission/InviteLogs"))
);
export const CouponManagement = Loadable(
  lazy(() => import("../pages/ReferralTools/CouponManagement/CouponManage"))
);
export const CreateCoupon = Loadable(
  lazy(() => import("../pages/ReferralTools/CouponManagement/CreateCoupon"))
);
export const BusinessMain = Loadable(
  lazy(() => import("../pages/UserManagement/Business/BusinessMain"))
);
export const AffliateMain = Loadable(
  lazy(() => import("../pages/UserManagement/Affliates/AffliateMain"))
);
export const CreateAffliateMain = Loadable(
  lazy(() => import("../pages/UserManagement/Affliates/CreateAffliateMain"))
);
export const SalesMain = Loadable(
  lazy(() => import("../pages/UserManagement/Sales/SalesMain"))
);
export const PaymentManagement = Loadable(
  lazy(() => import("../pages/Management/Payments/Main"))
);
export const ViewOrder = Loadable(
  lazy(() => import("../pages/Management/ViewOrder"))
);
export const Revenue = Loadable(
  lazy(() => import("../pages/Management/Payments/Revenue/Main"))
);
export const Activities = Loadable(
  lazy(() => import("../pages/Activities/Main"))
);
export const Reports = Loadable(
  lazy(() => import("../pages/Reports/ReportsExport"))
);
export const ExportReport = Loadable(
  lazy(() => import("../pages/FuntionalReports/AdminFunctionalReports"))
);
export const Dashboard = Loadable(
  lazy(() => import("../pages/Dashboard/Main"))
);
