import { Box, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { onBoardingTermsPolicy } from "../../redux/slices/admin";
import { useDispatch } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import { LoadingButton } from "@mui/lab";
import ApiService from "../../APIs/api";
import {
  Typography14,
  Typography18,
} from "../../components/Typography/Typography";
import CircularTableLoader from "../../components/Loader/CircularTableLoader";

const TermsAndPolicies = (props: any) => {
  const getData = useSelector((state: any) => state.user.user);

  const dispatch = useDispatch();
  const [btnDisabled, setbtnDisabled] = useState(true);
  const [SuccessMsg, setSuccessMsg] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");

  const [LoadingButtonDisabled, setLoadingButtonDisabled] = useState(false);
  const [agreementDocs, setagreementDocs] = useState<any>({
    termsOfService: "",
    privacyPolicy: "",
    disclaimer: "",
  });

  // on Change function for arrange data
  const handleOnChangeDocs = (event: any) => {
    setbtnDisabled(false);
    setSuccessMsg(false);
    setErrorMsg(false);
    const { name, value } = event.target;
    setagreementDocs((preve: any) => {
      return {
        ...preve,
        [name]: value,
      };
    });
  };

  const handleOnChangeUpdateTermsPolices = async (e: any) => {
    e.preventDefault();
    setLoadingButtonDisabled(true);
    setErrorMsg(false);
    setErrorMessage("");
    setbtnDisabled(true);
    await ApiService.setToken(props.props);
    const responseData: any = await ApiService.post(
      "/admin/setLimits",
      agreementDocs
    );
    if (responseData.status === 200) {
      dispatch(onBoardingTermsPolicy(responseData.data.result));
      setLoadingButtonDisabled(false);
      setSuccessMsg(true);
      setbtnDisabled(true);
    } else {
      setErrorMsg(true);
      setErrorMessage(
        responseData.response.data.error
          ? responseData.response.data.error
          : responseData.message
      );
      setLoadingButtonDisabled(false);
      setbtnDisabled(false);
    }
  };

  // set Data from store to terms and policies textfield through mounted useEffect hook
  useEffect(() => {
    let value = {
      termsOfService: getData.termsOfService,
      privacyPolicy: getData.privacyPolicy,
      disclaimer: getData.disclaimer,
    };
    setagreementDocs(value);
  }, [getData]);

  return (
    <Box
      boxShadow={
        "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 4px 0px rgba(145, 158, 171, 0.20)"
      }
      mx={2}
      my={1.5}
      sx={{ borderRadius: "8px", backgroundColor: "white" }}
    >
      {props.open ? (
        <CircularTableLoader />
      ) : (
        <Stack mx={4} my={2.5}>
          <Typography14
            color={"#556486"}
            fontWeight={500}
            text={"Set Terms & Policies for new users"}
          ></Typography14>

          <Box component={"form"} onSubmit={handleOnChangeUpdateTermsPolices}>
            <Stack spacing={2} mt={3}>
              <TextField
                margin="normal"
                multiline
                rows={3}
                InputLabelProps={{
                  style: {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: 14,
                  },
                }}
                name="termsOfService"
                id="termsOfService"
                label="Terms of Service"
                value={
                  agreementDocs.termsOfService
                    ? agreementDocs.termsOfService
                    : "N/A"
                }
                type="text"
                size="small"
                onChange={handleOnChangeDocs}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "grey",
                    fontSize: "12px",
                    fontFamily: "Public Sans",
                    fontWeight: 500,
                    lineHeight: "18px",
                  },
                }}
              />
              <TextField
                margin="normal"
                multiline
                rows={3}
                InputLabelProps={{
                  style: {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: 14,
                  },
                }}
                name="privacyPolicy"
                label="Private Policy"
                id="privacyPolicy"
                type="type"
                value={
                  agreementDocs.privacyPolicy
                    ? agreementDocs.privacyPolicy
                    : "N/A"
                }
                onChange={handleOnChangeDocs}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "grey",
                    fontSize: "12px",
                    fontFamily: "Public Sans",
                    fontWeight: 500,
                    lineHeight: "18px",
                  },
                }}
              />
              <TextField
                margin="normal"
                multiline
                rows={3}
                InputLabelProps={{
                  style: {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: 14,
                  },
                }}
                name="disclaimer"
                label="Disclaimer"
                value={
                  agreementDocs.disclaimer ? agreementDocs.disclaimer : "N/A"
                }
                onChange={handleOnChangeDocs}
                id="disclaimer"
                type="text"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "grey",
                    fontSize: "12px",
                    fontFamily: "Public Sans",
                    fontWeight: 500,
                    lineHeight: "18px",
                  },
                }}
              />
            </Stack>
            <Stack
              display={"flex"}
              direction={"row"}
              sx={{ mt: 4 }}
              spacing={2}
            >
              <LoadingButton
                size="small"
                disabled={btnDisabled}
                type="submit"
                sx={{
                  mt: 3,
                  minWidth: "25vw",
                  borderRadius: "8px",
                  fontSize: "14",
                  fontFamily: "Public Sans",
                  gap: "8px",
                  backgroundColor: btnDisabled
                    ? "rgba(145, 158, 171, 0.24)"
                    : "#303981",
                  textTransform: "none",
                  color: btnDisabled ? "" : "white",
                  "&:hover": {
                    backgroundColor: "#303981",
                  },
                }}
                loading={LoadingButtonDisabled}
              >
                Update
              </LoadingButton>
              {SuccessMsg ? (
                <div>
                  <Stack
                    display={"flex"}
                    direction={"row"}
                    spacing={0.5}
                    mt={0.6}
                  >
                    <DoneIcon style={{ fontSize: "20px", color: "#36B37E" }} />{" "}
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      fontFamily={"Public Sans"}
                      color={"#36B37E"}
                    >
                      {" "}
                      Terms updated Successfully
                    </Typography>
                  </Stack>
                </div>
              ) : (
                ""
              )}
              {ErrorMsg ? (
                <div>
                  <Stack
                    display={"flex"}
                    direction={"row"}
                    spacing={0.5}
                    mt={0.6}
                  >
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      fontFamily={"Public Sans"}
                      color={"red"}
                    >
                      {" "}
                      {ErrorMessage}
                    </Typography>
                  </Stack>
                </div>
              ) : (
                ""
              )}
            </Stack>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default TermsAndPolicies;
