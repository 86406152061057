import { useDispatch } from "react-redux";
import { ErrorMsg } from "../components/ErrorMsg";
import { logOutUser, onBoarding } from "../redux/slices/admin";
import { store } from "../redux/store"; // Import your Redux store

export const decodeToken = (token: string): any => {
  try {
    const payload = token.split(".")[1];
    return JSON.parse(atob(payload));
  } catch (error) {
    ErrorMsg("Invalid token ,please login again !");
    console.error("Invalid token format", error);
    return null;
  }
};

// Session timeout action
export const tokenSessionTimeout = async (token: string) => {
  const decodedToken = decodeToken(token);

  if (!decodedToken || !decodedToken.role) {
    window.location.href = "/";
    ErrorMsg("Invalid Token does not contain user role.");
    return false;
  }
  const role = decodedToken.role;
  if (role !== "Admin") {
    window.location.href = "/";
    ErrorMsg("Invalid user role.");
    return false;
  }

  if (!decodedToken || !decodedToken.exp) {
    window.location.href = "/";
    return false;
  }

  const expirationTime = decodedToken.exp * 1000; // Convert seconds to milliseconds
  const currentTime = Date.now();

  if (currentTime >= expirationTime) {
    window.location.href = "/";
    ErrorMsg("Token has expired.");
    return false;
  }
  const timeout = expirationTime - currentTime;
  if (!timeout) {
    window.location.href = "/";
    ErrorMsg("Token has expired, Please try again !");
    return false;
  }
  setTimeout(() => {
    ErrorMsg("Token has expired, Please try again !");
    store.dispatch(logOutUser({})); // Use store.dispatch here
    window.location.href = "/";
  }, timeout);
  return true;
};
