import { useEffect, useState } from "react";
import {
  AccountingIcon,
  Activities,
  AddAffiliate,
  AffiliateManagement,
  UserManagement,
  BusinessManagement,
  GST,
  Onboarding,
  Payment,
  PaymentTools,
  RevenueIcon,
  Dashboard,
  Orders,
  Reports,
  Fees,
  OrderValidity,
  CouponIcon,
  BusinessNetworkIcon,
  SmsTemplate,
} from "../../assets/icons/Menu";
import {
  Avatar,
  Stack,
  Box,
  Typography,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import { firstCharacterUppercase } from "../../utils/characterCases/Cases";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AP_Close_Logo, AP_Open_Logo } from "../../assets/icons/Logo";
import { Typography12, Typography14 } from "../Typography/Typography";
import { useSelector } from "react-redux";
import { Drawer, DrawerHeader } from "./SimpleDrawer";
import { VersionDate } from "./UI";

function Sidebars() {
  const userName = useSelector((state: any) => state.user.userData.username);
  const [userManagementMenu, setuserManagementMenu] = useState(false);
  const [PaymentsMenu, setPaymentsMenu] = useState(false);
  const [Accounting, setAccounting] = useState(false);
  const [paymentMenu, setpaymentMenu] = useState(false);
  const [open, setOpen] = useState(true);

  const handleDrawerClose = () => {
    setOpen(!open);
    setuserManagementMenu(false);
    setPaymentsMenu(false);
    setAccounting(false);
    setpaymentMenu(false);
  };

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const commonListItem = (
    open: boolean,
    icon: string,
    Title: string,
    link?: string
  ) => {
    return (
      <>
        {link?.length ? (
          <Link to={link} style={{ textDecoration: "none" }}>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {<img src={icon} alt="" />}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography14 text={Title} fontSize={20}></Typography14>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {<img src={icon} alt="" />}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography14 text={Title} fontSize={20}></Typography14>
                }
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </>
    );
  };
  return (
    <>
      <Drawer variant="permanent" open={open} anchor="left">
        <DrawerHeader onClick={handleDrawerClose}>
          {open ? (
            <img src={AP_Open_Logo} alt="" />
          ) : (
            <img src={AP_Close_Logo} alt="" />
          )}
        </DrawerHeader>

        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Box px={1}>
              <Box
                sx={{
                  borderRadius: "12px",
                  px: 0.5,
                  py: 3,
                  maxWidth: "240px",
                  backgroundColor: "rgba(145, 158, 171, 0.08)",
                }}
              >
                <Stack direction={"row"} display={"flex"} spacing={2}>
                  <Avatar />
                  <ListItemText
                    primary={
                      <Stack>
                        <Typography14
                          text={
                            userName ? firstCharacterUppercase(userName) : "N/A"
                          }
                          color={"rgba(33, 43, 54, 1)"}
                        ></Typography14>
                        <Typography12 text={"Admin"}></Typography12>
                      </Stack>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </Stack>
              </Box>
            </Box>
          </ListItem>
        </List>

        <List>
          {open ? (
            <Stack px={2} pt={1}>
              <Typography12 fontWeight={700} text={"GENERAL"}></Typography12>
            </Stack>
          ) : (
            ""
          )}

          {commonListItem(open, Dashboard, "Dashboard", "/app/dashboard")}
        </List>

        <List>
          {open ? (
            <Stack px={2} pt={0.5}>
              <Typography12 fontWeight={700} text={"MANAGEMENT"}></Typography12>
            </Stack>
          ) : (
            ""
          )}
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {<img src={UserManagement} alt="" />}
              </ListItemIcon>

              <ListItemText
                onClick={() => setuserManagementMenu(!userManagementMenu)}
                primary={<Typography14 text={"User Management"}></Typography14>}
                sx={{ opacity: open ? 1 : 0 }}
              />
              {open ? (
                userManagementMenu ? (
                  <ExpandMoreIcon
                    onClick={() => setuserManagementMenu(!userManagementMenu)}
                  />
                ) : (
                  <ChevronRightIcon
                    onClick={() => setuserManagementMenu(!userManagementMenu)}
                  />
                )
              ) : (
                ""
              )}
            </ListItemButton>
            <Collapse in={userManagementMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <ListItem> */}

                {commonListItem(
                  open,
                  BusinessManagement,
                  "Businesses",
                  "/app/userlist"
                )}
                {commonListItem(
                  open,
                  AffiliateManagement,
                  "Affiliate",
                  "/app/affliate"
                )}
              </List>
            </Collapse>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {<img src={Payment} alt="" />}
              </ListItemIcon>
              <ListItemText
                onClick={() => setPaymentsMenu(!PaymentsMenu)}
                primary={<Typography14 text={"Payments"}></Typography14>}
                sx={{ opacity: open ? 1 : 0 }}
              />
              {open ? (
                PaymentsMenu ? (
                  <ExpandMoreIcon
                    onClick={() => setPaymentsMenu(!PaymentsMenu)}
                  />
                ) : (
                  <ChevronRightIcon
                    onClick={() => setPaymentsMenu(!PaymentsMenu)}
                  />
                )
              ) : (
                ""
              )}
            </ListItemButton>
            <Collapse in={PaymentsMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {commonListItem(open, Orders, "Orders", "/app/payment/list")}
              </List>
            </Collapse>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={AccountingIcon} alt="" />
              </ListItemIcon>
              <ListItemText
                onClick={() => setAccounting(!Accounting)}
                primary={<Typography14 text={"Accounting"}></Typography14>}
                sx={{ opacity: open ? 1 : 0 }}
              />
              {open ? (
                Accounting ? (
                  <ExpandMoreIcon onClick={() => setAccounting(!Accounting)} />
                ) : (
                  <ChevronRightIcon
                    onClick={() => setAccounting(!Accounting)}
                  />
                )
              ) : (
                ""
              )}
            </ListItemButton>
            <Collapse in={Accounting} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link
                  to={"/app/payment/revenue"}
                  style={{ textDecoration: "none" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {<img src={RevenueIcon} alt="" />}
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <Typography14 text={"AP Revenue / GST"}></Typography14>
                      }
                    />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            {commonListItem(open, Activities, "Activities", "/app/activities")}
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link
              to={"/app/reports"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img height={"24px"} width={"24px"} src={Reports} alt="" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography14 text={"Bank Api Logs"}></Typography14>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link
              to={"/app/downloadreports"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img height={"24px"} width={"24px"} src={Reports} alt="" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography14 text={"Export Reports"}></Typography14>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </ListItem>
        </List>
        <List>
          {open ? (
            <Stack px={2} pt={0.5}>
              <Typography12 fontWeight={700} text={"TOOLS"}></Typography12>
            </Stack>
          ) : (
            ""
          )}
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link
              to={"/app/onboarding"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img src={Onboarding} alt="" />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography14 text={"Onboarding"}></Typography14>}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to={"/app/commission"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img src={AddAffiliate} alt="" />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography14 text={"Affiliate"}></Typography14>}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src={Payment} alt="" />
                </ListItemIcon>
                <ListItemText
                  onClick={() => setpaymentMenu(!paymentMenu)}
                  primary={<Typography14 text={"Payment"}></Typography14>}
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {open ? (
                  paymentMenu ? (
                    <ExpandMoreIcon
                      onClick={() => setpaymentMenu(!paymentMenu)}
                    />
                  ) : (
                    <ChevronRightIcon
                      onClick={() => setpaymentMenu(!paymentMenu)}
                    />
                  )
                ) : (
                  ""
                )}
              </ListItemButton>
              <Collapse in={paymentMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {/* <Link to={"/app/fees"} style={{ textDecoration: "none" }}>
                    <ListItem style={{ paddingLeft: "40px" }}>
                      <ListItemText
                        primary={<Typography14 text={"AP Fee"}></Typography14>}
                      />
                    </ListItem>
                  </Link> */}
                  <Link
                    to={"/app/fees"}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img src={Fees} alt="" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography14 text={"AP Fees"}></Typography14>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link
                    to={"/app/duration"}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img src={OrderValidity} alt="" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography14
                              text={"Order Validity"}
                            ></Typography14>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </ListItem>

            <Link
              to={"/app/businessnetwork"}
              style={{ textDecoration: "none" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src={BusinessNetworkIcon} alt="" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography14 text={"Business Network"}></Typography14>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>

            <Link to={"/app/coupons"} style={{ textDecoration: "none" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src={CouponIcon} alt="" />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography14 text={"Coupons"}></Typography14>}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
            {/* <Link to={"/app/coupons"} style={{ textDecoration: "none" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src={SmsTemplate} alt="" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography14 text={"SMS Email Templates"}></Typography14>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link> */}
          </ListItem>
        </List>
        {open ? <VersionDate /> : ""}
      </Drawer>
    </>
  );
}
export default Sidebars;
