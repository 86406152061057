import {
  Autocomplete,
  Box,
  Divider,
  Stack,
  TablePagination,
  TextField,
} from "@mui/material";
import { Typography14 } from "../../components/Typography/Typography";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { findObjectLength } from "../../utils/reusableFunction/resusableFn";
import DoneIcon from "@mui/icons-material/Done";
import ApiService from "../../APIs/api";
import { useSelector } from "react-redux";

const AddCategory = (props: any) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [btnDisabled, setbtnDisabled] = useState(true);
  const [verifyLoading, setverifyLoading] = useState(false);
  const [category, setcategory] = useState("");
  const [IndustryList, setIndustryList] = useState<any>([]);
  const [selectIndustrySearchObj, setselectIndustrySearchObj] = useState<any>(
    {}
  );
  const [categoryList, setcategoryList] = useState<any>([]);
  const [categorDisabled, setcategorDisabled] = useState(true);
  const [categoryErrorMsg, setcategoryErrorMsg] = useState(false);
  const [categoryAddSuccessMsg, setcategoryAddSuccessMsg] = useState(false);

  const handleonClickAddCategory = async (e: any) => {
    e.preventDefault();
    setverifyLoading(true);
    let data = new FormData(e.currentTarget);
    let body = {
      categoryName: data.get("categoryName"),
      industryId: selectIndustrySearchObj._id
        ? selectIndustrySearchObj._id
        : "",
    };
    const responseData: any = await ApiService.post("/admin/addCategory", body);
    if (responseData.status === 200) {
      setverifyLoading(false);
      setbtnDisabled(true);
      setcategory("");
      setcategoryAddSuccessMsg(true);
      OnMountedCategoryList();
    } else {
      setbtnDisabled(true);
      setverifyLoading(false);
      setcategoryErrorMsg(true);
    }
  };
  const getIndustryListBySearch = async () => {
    let params = {
      limit: rowsPerPage,
      page: page + 1,
      searchKey: "",
    };
    const responseData: any = await ApiService.get(
      "/admin/getIndustry",
      params
    );
    if (responseData.status === 200) {
      let data = responseData.data.result;
      setIndustryList(data);
    } else {
      console.log(responseData);
    }
  };
  const handleOnChangeAddCategory = (e: any) => {
    setcategoryAddSuccessMsg(false);
    setcategory(e.target.value);
    if (e.target.value !== "") {
      setbtnDisabled(false);
    } else {
      setbtnDisabled(true);
    }
  };
  useEffect(() => {
    if (findObjectLength(selectIndustrySearchObj) > 0) {
      setcategorDisabled(false);
    } else {
      setcategorDisabled(true);
    }
  });
  const OnMountedCategoryList = async () => {
    let params = {
      limit: rowsPerPage,
      page: page + 1,
      industryId: selectIndustrySearchObj._id
        ? selectIndustrySearchObj._id
        : "",
    };
    const responseData: any = await ApiService.get(
      "/admin/getCategory",
      params
    );
    if (responseData.status === 200) {
      let data: any = responseData.data.result;
      let total: any = responseData.data.total;
      console.log(data, "response data");
      setcategoryList(data);
      setTotalCount(total);
    } else {
      console.log(responseData);
    }
  };

  useEffect(() => {
    if (findObjectLength(selectIndustrySearchObj)) {
      OnMountedCategoryList();
    }
  }, [selectIndustrySearchObj, page, rowsPerPage]);

  return (
    <>
      {" "}
      <Box
        mx={4}
        my={2.5}
        pb={3}
        component={"form"}
        onSubmit={handleonClickAddCategory}
      >
        <Stack px={2} spacing={2} mt={2} direction={"row"} display={"flex"}>
          <Stack spacing={1.5}>
            <Typography14
              color={"#556486"}
              text={"Select Industry"}
            ></Typography14>
            <Autocomplete
              size="small"
              disableClearable
              options={IndustryList}
              getOptionLabel={(option: any) => option.industryName}
              onChange={(event: any, value: any) =>
                setselectIndustrySearchObj(value !== null ? value : {})
              }
              fullWidth
              sx={{
                minWidth: "25vw",
              }}
              renderInput={(params: any) => (
                <TextField
                  fullWidth
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "#919EAB",
                      fontFamily: "Public Sans",
                      fontWeight: "400",
                      fontStyle: "normal",
                      fontSize: 14,
                      lineHeight: "14px",
                    },
                    "& .MuiFormHelperText-root": {
                      fontFamily: "Public Sans",
                      fontWeight: 400,
                    },
                  }}
                  {...params}
                  onFocus={getIndustryListBySearch}
                  onChange={getIndustryListBySearch}
                  InputLabelProps={{
                    sx: {
                      fontSize: "16px",
                      fontFamily: "Public Sans",
                      color: "#919EAB",
                      height: "40px",
                      fontWeight: 400,
                    },
                  }}
                  label="Search Industry"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    style: {
                      borderRadius: "8px",
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Stack spacing={1.5}>
            <Typography14
              color={"#556486"}
              text={"Select Category"}
            ></Typography14>
            <TextField
              size="small"
              sx={{
                "& .MuiFormLabel-root": {
                  color: "#919EAB",
                  fontFamily: "Public Sans",
                  fontWeight: "400",
                  fontStyle: "normal",
                  fontSize: 14,
                  lineHeight: "14px",
                },
                "& .MuiFormHelperText-root": {
                  fontFamily: "Public Sans",
                  fontWeight: 400,
                },
              }}
              disabled={categorDisabled}
              value={category}
              InputProps={{
                style: {
                  borderRadius: "8px",
                  height: "40px",
                  borderColor: "#919EAB",
                  minWidth: "25vw",
                },
              }}
              label={"Category"}
              name="categoryName"
              onChange={handleOnChangeAddCategory}
            />
          </Stack>
        </Stack>

        <Box sx={{ px: 2, py: 3 }}>
          <Stack display={"flex"} direction={"row"} spacing={2}>
            <LoadingButton
              size="small"
              disabled={btnDisabled}
              sx={{
                borderRadius: "8px",
                px: 4,
                fontSize: "14",
                fontFamily: "Public Sans",
                gap: "8px",
                backgroundColor: btnDisabled
                  ? "rgba(145, 158, 171, 0.24)"
                  : "#303981",
                textTransform: "none",
                color: btnDisabled ? "grey" : "white",
                "&:hover": {
                  backgroundColor: btnDisabled
                    ? "rgba(145, 158, 171, 0.24)"
                    : "#303981",
                },
              }}
              loading={verifyLoading}
              type="submit"
            >
              Add
            </LoadingButton>
            {categoryAddSuccessMsg ? (
              <Box>
                <Stack
                  display={"flex"}
                  direction={"row"}
                  spacing={0.5}
                  mt={0.6}
                >
                  <DoneIcon style={{ fontSize: "20px", color: "#36B37E" }} />{" "}
                  <Typography14
                    fontWeight={600}
                    color={"#36B37E"}
                    text={"Category added successfully !"}
                  >
                    {" "}
                  </Typography14>
                </Stack>
              </Box>
            ) : (
              ""
            )}
            {categoryErrorMsg ? (
              <Box>
                <Stack
                  display={"flex"}
                  direction={"row"}
                  spacing={0.5}
                  mt={0.6}
                >
                  <Typography14
                    fontWeight={600}
                    color={"red"}
                    text={"Something went wrong, please try again !"}
                  >
                    {" "}
                  </Typography14>
                </Stack>
              </Box>
            ) : (
              ""
            )}
          </Stack>
        </Box>

        <Box
          boxShadow={
            "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 4px 0px rgba(145, 158, 171, 0.20)"
          }
          mx={2}
          my={3}
          sx={{
            maxWidth: "35vw",
            borderRadius: "8px",
            backgroundColor: "white",
          }}
        >
          <Box
            sx={{
              backgroundColor: "var(--background-light-neutral, #F4F6F8)",
              px: 4,
              py: 3,
            }}
          >
            <Typography14 color={"#556486"} text={"Categories"}></Typography14>
          </Box>
          <Box>
            <Stack sx={{ px: 4, py: 2 }} spacing={3}>
              {categoryList.length > 0 ? (
                categoryList.map((item: any, index: any) => (
                  <Typography14
                    text={item ? item.categoryName : "N/A"}
                  ></Typography14>
                ))
              ) : (
                <Typography14
                  sx={{ textAlign: "center" }}
                  text={"No Category Found !"}
                  color={"dark"}
                ></Typography14>
              )}
            </Stack>
            <Divider></Divider>
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"end"}
              px={2}
            >
              <TablePagination
                component="div"
                count={totalCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddCategory;
