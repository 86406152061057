import { Box, Typography } from "@mui/material";

const Footer = () => {
  const companyName = process.env.companyName || "AssuredPay";
  return (
    <Box
      sx={{
        bottom: 0,
        width: "100%",
        backgroundColor: "#F7F9FB",
        textAlign: "center",
        paddingTop: "5px",
        paddingBottom: "10px",
        pr: "-20px",
        position: "sticky",

        boxShadow: "0px -2px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        component="footer"
      >
        <Typography
          fontWeight={700}
          color={"var(--text-light-secondary, #637381)"}
          fontFamily={"Public Sans"}
          fontSize={14}
        >
          © {new Date().getFullYear()} {companyName}.
        </Typography>
        <Typography variant="body2" color="textSecondary">
          All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
