import { m } from "framer-motion";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { AppBar, Box, Button, Stack, Typography } from "@mui/material";
import { MotionContainer, varBounce } from "../../components/animate";
import { PageNotFoundIllustration } from "../../assets/illustrations";
import { Typography14 } from "../../components/Typography/Typography";
import Footer from "../../components/Footer";
import { Toaster } from "react-hot-toast";
import Header from "../../components/Header";
export default function PageNotFound() {
  const navigate = useNavigate();
  const handleChange = () => {
    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title> 404 Page Not Found | AssuredPay</title>
      </Helmet>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          overflowX: "hidden", 
          width: "100vw", 
        }}
      >
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              backgroundColor: "rgba(145, 158, 171, 0.08)",
              overflowX: "hidden",
            }}
          >
            <AppBar
              style={{
                backgroundColor: "white",
                boxShadow:
                  "0px 6px 12px -4px rgba(145, 158, 171, 0.12), 0px 0px 0px 0px rgba(145, 158, 171, 0.20)",
              }}
            >
              <Toaster />
              <Header />
            </AppBar>
            <Box
              sx={{
                minHeight: "calc(100vh - 100px)", 
                paddingTop: 5,
                overflowX: "hidden", 
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "100vh",
                  textAlign: "center",
                  px: 2,
                }}
              >
                <MotionContainer>
                  <m.div variants={varBounce().in}>
                    <Typography variant="h3" paragraph>
                      Sorry, page not found!
                    </Typography>
                  </m.div>

                  <m.div variants={varBounce().in}>
                    <Typography sx={{ color: "text.secondary", mb: 3 }}>
                      We couldn’t find the page you’re looking for. Perhaps
                      you’ve mistyped the URL? Be sure to check your spelling.
                    </Typography>
                  </m.div>

                  <m.div variants={varBounce().in}>
                    <PageNotFoundIllustration
                      sx={{
                        height: { xs: 180, sm: 260 },
                        mb: 3,
                      }}
                    />
                  </m.div>

                  <m.div variants={varBounce().in}>
                    <Button
                      onClick={handleChange}
                      size="large"
                      variant="contained"
                      sx={{
                        backgroundColor: "#303189",
                        color: "#fff", 
                        "&:hover": {
                          backgroundColor: "#202060", 
                        },
                        borderRadius: "8px",
                        mt: 2,
                      }}
                    >
                      <Typography14
                        sx={{ color: "white" }}
                        text={"Go to Home"}
                      ></Typography14>
                    </Button>
                  </m.div>
                </MotionContainer>
              </Box>
              <Box
                sx={{
                  bottom: 0,
                  width: "100%",
                  backgroundColor: "#F7F9FB",
                  textAlign: "center",
                  paddingTop: "5px",
                  paddingBottom: "10px",
                  pr: "-20px",
                  position: "fixed !important",

                  boxShadow: "0px -2px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Footer />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
