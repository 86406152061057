import {
  Box,
  Stack,
  Tabs,
  Tab,
} from "@mui/material";
import * as React from "react";
import {
  Typography14,
  Typography20,
} from "../../components/Typography/Typography";
import AddIndustry from "./AddIndustry";
import AddCategory from "./AddCategory";
import AddProducts from "./AddProducts";
import { useState } from "react";
import { useSelector } from "react-redux";

const BusinessNetworkTools = () => {
  const token = useSelector((state: any) => state.user.userLogin.token);

  const [value, setValue] = useState("one");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box justifyContent={"center"} component={"div"}>
      <Stack>
        <Typography20
          sx={{ mt: 5, mx: 2 }}
          color={"#000000"}
          text={"Business Network Tools"}
        ></Typography20>
      </Stack>
      <Box
        boxShadow={
          "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 4px 0px rgba(145, 158, 171, 0.20)"
        }
        mx={2}
        my={1.5}
        sx={{ borderRadius: "16px", backgroundColor: "white" }}
      >
        <Box my={1}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "#303981",
              },
              "& .MuiTabs-indicator": {
                ml: 2,
                maxWidth: "61px",
                backgroundColor: "#303981",
              },
            }}
          >
            <Tab
              value="one"
              label={
                <Typography14
                  color={"#334A67"}
                  fontSize={"14px"}
                  text={"Industry"}
                  sx={{ textTransform: "none" }}
                ></Typography14>
              }
              sx={{ textTransform: "none" }}
            />
            <Tab
              value="two"
              label={
                <Typography14
                  color={"#334A67"}
                  fontSize={"14px"}
                  text={"Category"}
                  sx={{ textTransform: "none" }}
                ></Typography14>
              }
            />
            <Tab
              value="three"
              label={
                <Typography14
                  color={"#334A67"}
                  fontSize={"14px"}
                  text={"Product"}
                  sx={{ textTransform: "none" }}
                ></Typography14>
              }
              sx={{ textTransform: "none" }}
            />
          </Tabs>
        </Box>
        {value === "one" ? <AddIndustry value={value} token={token} /> : ""}
        {value === "two" ? <AddCategory value={value} token={token} /> : ""}
        {value === "three" ? <AddProducts value={value} token={token} /> : ""}
      </Box>
    </Box>
  );
};

export default BusinessNetworkTools;
