import { Box, Stack, Typography } from "@mui/material";
import OnBoardingLimits from "./OnBoardingLimits";
import TermsAndPolicies from "./TermsAndPolicies";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { onBoarding } from "../../redux/slices/admin";
import OnBoardingFees from "./OnBoardingFees";
import ApiService from "../../APIs/api";
import { useSelector } from "react-redux";
import { ErrorMsg } from "../../components/ErrorMsg";
import LoadingScreen from "../../components/loadingScreen";

const OnBoardingTools = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const token = useSelector((state: any) => state.user.userLogin.token);

  const OnboadingData = async () => {
    setOpen(true);
    await ApiService.setToken(token);
    const responseData: any = await ApiService.get("admin/getconfiguration");
    if (responseData.status === 200) {
      let data: any = responseData.data.result;
      dispatch(onBoarding(data));
      setOpen(false);
    } else {
      ErrorMsg("Something went wrong, Please try again !");
      setOpen(false);

      console.log(responseData);
    }
  };

  useEffect(() => {
    OnboadingData();
  }, []);
  return (
    <>
      <Box justifyContent={"center"} component={"div"}>
        <Stack spacing={2} flexDirection={"column"} mt={3} mx={2}>
          <Typography
            fontFamily={"Public Sans"}
            fontSize={22}
            sx={{ mx: 2, mt: 4 }}
            fontWeight={700}
            fontStyle={"normal"}
            color={"#000000"}
          >
            Onboarding Tools
          </Typography>
          <OnBoardingLimits props={token} open={open} />
          <TermsAndPolicies props={token} open={open} />
          <OnBoardingFees props={token} open={open} />
        </Stack>
      </Box>
    </>
  );
};

export default OnBoardingTools;
