import { Tab, TablePagination, Tabs, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { TypographyText } from "../../components/userPage/typography/TypographyText";
import TableList from "./TableList";
import { useEffect, useState } from "react";
import { reportIdentifiers } from "../../utils/Constant";
import ApiService from "../../APIs/api";
import { useSelector } from "react-redux";

const ReportsExport = (props: any) => {
  const token = useSelector((state: any) => state.user.userLogin.token);
  const [open, setOpen] = useState(false);
  const [reportIdentifier, setReportIdentifier] = useState("batch");
  const [batchData, setBatchData] = useState([]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    console.log("new value", newValue);
    setReportIdentifier(newValue);
    // handleChangeClear();
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const array: any = [];

  const fetchBatchReports = async () => {
    setBatchData([]);
    setOpen(true);
    await ApiService.setToken(token);
    const responseData: any = await ApiService.get(`admin/api-logs`);
    if (responseData.status === 200) {
      const data = responseData.data.data;
      setTotalCount(responseData.data.total);
      if (data.length > 0) {
        setOpen(false);

        setBatchData(data);
      }
    }
  };
  useEffect(() => {
    fetchBatchReports();
  }, [rowsPerPage, page]);
  return (
    <>
      <Box px={3} borderRadius={"4px"}>
        {/* <Box pt={2}>
          <CategorizedTabs />
        </Box> */}

        <>
          <Box
            justifyContent={"center"}
            component={"div"}
            sx={{
              minHeight: "90vh",
            }}
          >
            <Stack mx={3}>
              <Typography
                fontFamily={"Public Sans"}
                fontSize={22}
                sx={{ mt: 4 }}
                fontWeight={700}
                fontStyle={"normal"}
                color={"#000000"}
              >
                Bank Api Logs
              </Typography>
            </Stack>
            <Box
              mx={3}
              my={2}
              border={"1px solid #919EAB"}
              boxShadow={
                "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
              }
              borderRadius={"16px"}
              sx={{
                borderStyle: "dashed",
                borderColor: "#d4dadf",
              }}
            >
              <Box
                boxShadow={1}
                sx={{ borderRadius: "8px", backgroundColor: "white" }}
              >
                <Tabs
                  onChange={handleChange}
                  // value={identifier}
                  value={reportIdentifier}
                  textColor="secondary"
                  indicatorColor="secondary"
                  sx={{
                    "& .MuiTabs-indicator": {
                      width: "auto",
                      backgroundColor: "grey",
                    },
                  }}
                >
                  <Tab
                    value="batch"
                    sx={{ textTransform: "none", px: 4 }}
                    label={
                      <Stack>
                        <TypographyText text={"Batch"} />
                      </Stack>
                    }
                  />
                </Tabs>
              </Box>
              <TableList props={batchData} open={open} />
              <Box>
                <Stack
                  display={"flex"}
                  direction={"row"}
                  justifyContent={"end"}
                >
                  <TablePagination
                    component="div"
                    count={totalCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Stack>
              </Box>
            </Box>
          </Box>
        </>
      </Box>
    </>
  );
};

export default ReportsExport;
