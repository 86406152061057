import { GST_Details_heading } from "../../../utils/characterCases/Cases";
import { experimentalStyled as styled } from "@mui/material/styles";
import {
  Typography,
  Grid,
  Paper,
  Box,
} from "@mui/material";

const Gst_Details = (props: any) => {
  const GST_Info: any = props ? props.gst_Details : {};

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));
  return (
    <>
      {GST_Info &&
        Object.keys(GST_Info).map((name: any, index: any) => (
          <Grid sx={{ px: "12px", py: "7px" }} xs={2} sm={4} md={4} key={index}>
            <Item>
              <Typography
                fontFamily={"Public Sans"}
                fontSize={11}
                color={"#919EAB"}
              >
                {GST_Details_heading(name)}
              </Typography>
              <Typography
                fontSize={"13px"}
                fontFamily={"Public Sans"}
                color={"#212B36"}
                fontWeight={500}
              >
                {GST_Info[name] ? GST_Info[name] : "N/A"}
              </Typography>
            </Item>
          </Grid>
        ))}
    </>
  );
};

export default Gst_Details;
