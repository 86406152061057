import { AP_Close_Logo, AP_Open_Logo } from "../assets/icons/Logo";

import {
  AppBar,
  Avatar,
  Box,
  Toolbar,
  Popover,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { login, logOutUser, onBoarding } from "../redux/slices/admin";
import { useDispatch } from "react-redux";

const Header = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const loginUser = useSelector((state: any) => state.user.login);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleAvatarClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(login(false));
    dispatch(logOutUser({}));
    dispatch(onBoarding({}));
    navigate("/"); 
  };
  const token = useSelector((state: any) => state.user?.userLogin?.token);
  useEffect(() => {
    if (!token) {
      dispatch(logOutUser({}));
    }
  }, [token]);
  const open = Boolean(anchorEl);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "white",
          boxShadow:
            "0px 6px 12px -4px rgba(145, 158, 171, 0.12), 0px 0px 0px 0px rgba(145, 158, 171, 0.20)",
        }}
      >
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            {props.collapsed === undefined ? (
              <img src={AP_Open_Logo} alt="" />
            ) : (
              ""
            )}
          </div>

          <div>
            {loginUser ? (
              <>
                <Avatar onClick={handleAvatarClick} />
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  sx={{
                    borderRadius: "12px",
                    mt: 1,
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "12px",
                      px: 2,
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontFamily={"Public Sans"}
                      fontWeight={600}
                      sx={{ py: 1, px: 2 }}
                      onClick={handleLogout}
                      style={{ cursor: "pointer" }}
                    >
                      Logout
                    </Typography>
                  </Box>
                </Popover>
              </>
            ) : (
              ""
            )}
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
