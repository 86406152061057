import Header from "../components/Header";
import { Toaster } from "react-hot-toast";
import ForgotPassword from "../pages/auth/ForgotPassword";
import { AppBar, Box } from "@mui/material";
import Footer from "../components/Footer";
import LoginPage from "../pages/auth/LoginPage";
import TwoStepVerification from "../pages/auth/TwoStepAuthentication";
import { Router } from "../routes";
import { useProSidebar } from "react-pro-sidebar";
import Sidebars from "../components/Sidebar/Sidebars";

export const LoginRouter = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflowX: "hidden", 
        width: "100vw", 
      }}
    >
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "rgba(145, 158, 171, 0.08)",
            overflowX: "hidden", 
          }}
        >
          <AppBar
            style={{
              backgroundColor: "white",
              boxShadow:
                "0px 6px 12px -4px rgba(145, 158, 171, 0.12), 0px 0px 0px 0px rgba(145, 158, 171, 0.20)",
            }}
          >
            <Toaster />
            <Header />
          </AppBar>
          <Box
            sx={{
              minHeight: "calc(100vh - 100px)", 
              paddingTop: 5,
              overflowX: "hidden", 
            }}
          >
            <LoginPage />
          </Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export const ForgotPassowrdRouter = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflowX: "hidden", 
        width: "100vw", 
      }}
    >
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "rgba(145, 158, 171, 0.08)",
            overflowX: "hidden", 
          }}
        >
          <AppBar
            style={{
              backgroundColor: "white",
              boxShadow:
                "0px 6px 12px -4px rgba(145, 158, 171, 0.12), 0px 0px 0px 0px rgba(145, 158, 171, 0.20)",
            }}
          >
            <Toaster />
            <Header />
          </AppBar>
          <Box
            sx={{
              minHeight: "calc(100vh - 100px)", 
              paddingTop: 5,
              overflowX: "hidden", 
            }}
          >
            <ForgotPassword />
          </Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export const TwoStepVerificationRouter = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflowX: "hidden", 
        width: "100vw", 
      }}
    >
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "rgba(145, 158, 171, 0.08)",
            overflowX: "hidden", 
          }}
        >
          <AppBar
            style={{
              backgroundColor: "white",
              boxShadow:
                "0px 6px 12px -4px rgba(145, 158, 171, 0.12), 0px 0px 0px 0px rgba(145, 158, 171, 0.20)",
            }}
          >
            <Toaster />
            <Header />
          </AppBar>
          <Box
            sx={{
              minHeight: "calc(100vh - 100px)", 
              paddingTop: 5,
              overflowX: "hidden", 
            }}
          >
            <TwoStepVerification />
          </Box>
          <Box
            sx={{
              bottom: 0,
              width: "100%",
              backgroundColor: "#F7F9FB",
              textAlign: "center",
              paddingTop: "5px",
              paddingBottom: "10px",
              pr: "-20px",
              position: "fixed !important",

              boxShadow: "0px -2px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const DashboardRouter = () => {
  const collapsed = useProSidebar().collapsed;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Sidebars />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "rgba(145, 158, 171, 0.08)",
          }}
        >
          <AppBar
            style={{
              backgroundColor: "white",
              boxShadow:
                "0px 6px 12px -4px rgba(145, 158, 171, 0.12), 0px 0px 0px 0px rgba(145, 158, 171, 0.20)",
            }}
          >
            <Toaster />
            <Header collapsed={collapsed} />
          </AppBar>
          <Box
            sx={{
              minHeight: "calc(100vh - 100px)", 
              paddingTop: 5,
            }}
          >
            <Router />
          </Box>

          <Footer />
        </Box>
      </Box>
    </Box>
  );
};
