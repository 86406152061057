import { Box, Typography } from "@mui/material";

const CircularTableLoader: React.FC = () => {
  const fullText = "Please Wait, Records are fetching . . .";

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      my={4}
      width="100%"
    >
      {/* Circular loader with Ashoka Chakra-like animation */}
      <Box
        sx={{
          position: "relative",
          width: "25px",
          height: "25px",
          mb: 2, // Margin-bottom to separate text from the loader
        }}
      >
        {Array.from({ length: 12 }).map((_, index) => (
          <Box
            key={index}
            sx={{
              position: "absolute",
              width: "4px",
              height: "10px",
              backgroundColor: "#303981",
              borderRadius: "2px",
              transformOrigin: "12.5px 12.5px",
              transform: `rotate(${index * 30}deg) translate(0, -10px)`,
              animation: `fade 1.2s infinite`,
              animationDelay: `${index * 0.1}s`,
            }}
          />
        ))}
        {/* Keyframe for fading in and out */}
        <style>
          {`
            @keyframes fade {
              0%, 100% { opacity: 0; }
              50% { opacity: 1; }
            }
          `}
        </style>
      </Box>

      {/* Animated text */}
      <Typography
        fontSize={14}
        color="#757575"
        mt={1} // Margin-top for spacing from the loader
      >
        {fullText}
      </Typography>
    </Box>
  );
};

export default CircularTableLoader;
