import { useRoutes } from "react-router-dom";
import {
  SetPartnerCommission,
  DurationMain,
  PaymentMain,
  InviteLogs,
  CreateCoupon,
  BusinessMain,
  AffliateMain,
  CreateAffliateMain,
  SalesMain,
  CouponManagement,
  PaymentManagement,
  ViewOrder,
  Revenue,
  Activities,
  ExportReport,
  Dashboard,
} from "./elements";
import PageNotFound from "../pages/404/PageNotFound";
import { ProtectedRoute } from "./authentication";
import OnBoardingTools from "../pages/OnBoarding/OnBoardingMain";
import Main from "../pages/user/Main";
import BusinessReferenceNumber from "../pages/user/Business/BusinessReferenceNumber";
import CreateUser from "../pages/UserManagement/CreateUser";
import ViewUsers from "../pages/UserManagement/ViewUsers";
import BusinessNetworkTools from "../pages/businessNetwork/BusinessNetworkTools";
import ReportsExport from "../pages/Reports/ReportsExport";

const protectedRoutes = [
  { path: "/onboarding", element: <OnBoardingTools /> },
  { path: "/userlist", element: <Main /> },
  { path: "/reference", element: <BusinessReferenceNumber /> },
  { path: "/createuser", element: <CreateUser /> },
  { path: "/users", element: <ViewUsers /> },
  { path: "/businessnetwork", element: <BusinessNetworkTools /> },
  { path: "/fees", element: <PaymentMain /> },
  { path: "/duration", element: <DurationMain /> },
  { path: "/commission", element: <SetPartnerCommission /> },
  { path: "/invite", element: <InviteLogs /> },
  { path: "/coupons", element: <CouponManagement /> },
  { path: "/createcoupon", element: <CreateCoupon /> },
  { path: "/business", element: <BusinessMain /> },
  { path: "/affliate", element: <AffliateMain /> },
  { path: "/addaffliate", element: <CreateAffliateMain /> },
  { path: "/sales", element: <SalesMain /> },
  { path: "/payment/list", element: <PaymentManagement /> },
  { path: "/payment/order", element: <ViewOrder /> },
  { path: "/payment/revenue", element: <Revenue /> },
  { path: "/activities", element: <Activities /> },
  { path: "/reports", element: <ReportsExport /> },
  { path: "/downloadreports", element: <ExportReport /> },
  { path: "/dashboard", element: <Dashboard /> },
];

export function Router() {
  return useRoutes([
    ...protectedRoutes.map((route) => ({
      path: route.path,
      element: <ProtectedRoute element={route.element} />,
    })),
    // Catch-all 404 Route for unmatched paths
    { path: "*", element: <PageNotFound /> },
  ]);
}
