import { experimentalStyled as styled } from "@mui/material/styles";
import { Typography, Grid, Paper } from "@mui/material";
import { changeTextAdharProfile } from "../../../utils/characterCases/Cases";
const AadharDetails = (props: any) => {
  const aadhar_info: any = props ? props.aadhar_info : {};
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));
  return (
    <>
      {Object.keys(aadhar_info).map((name: any, index: any) => (
        <Grid
        sx={{ px: "12px", py: "7px", }}
        xs={2}
        sm={4}
        md={4}
          style={{
            padding: "8px",
          }}
          key={index}
        >
          <Item>
            <Typography
              fontFamily={"Public Sans"}
              fontSize={11}
              color={"#919EAB"}
            >
              {changeTextAdharProfile(name)}
            </Typography>
            <Typography
              fontSize={"13px"}
              fontFamily={"Public Sans"}
              color={"#212B36"}
              fontWeight={500}
            >
              {aadhar_info[name] ? aadhar_info[name] : "N/A"}
            </Typography>
          </Item>
        </Grid>
      ))}
    </>
  );
};

export default AadharDetails;
