import { Stack, Box, Typography } from "@mui/material";
import { formatedData } from "../../utils/Validations/ValidationsFn";
const { version, lastUpdated } = require("../../../package.json");

export const VersionDate = () => {
  return (
    <Box
      sx={{
        position: "relative",
        padding: 1.5,
        backgroundColor: "#fff",
        borderTop: "1px solid #ddd",
        textAlign: "center",
        zIndex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "auto", 
      }}
    >
      <Stack display={"flex"} direction={"row"} spacing={0.5}>
        <Typography
          fontWeight={600}
          color={"var(--text-light-secondary, #637381)"}
          fontFamily={"Public Sans"}
          fontSize={14}
        >
          Version -
        </Typography>
        <Typography
          fontWeight={700}
          color={"var(--text-light-secondary, #637381)"}
          fontFamily={"Public Sans"}
          fontSize={14}
        >
          {version || "0.0.0.0"}
        </Typography>
      </Stack>

      <Typography
        fontWeight={600}
        color={"var(--text-light-secondary, #637381)"}
        fontFamily={"Public Sans"}
        fontSize={11}
      >
        {(lastUpdated && formatedData(lastUpdated)) || ""}
      </Typography>
    </Box>
  );
};
