import ApiService from "../APIs/api";
import { pathUrl } from "../utils/Constant";

export const generatePresignedUrl = async (token: string, url: string) => {
  console.log("usrrlrlrlr", url);
  try {
    await ApiService.setToken(token);
    const response: any = await ApiService.get(`${pathUrl.presignedUrl}`, {
      url,
    });

    if (response.status === 200) {
      return response.data.presignedUrl; 
    }
  } catch (error) {
    return url;
  }

  return url; 
};
