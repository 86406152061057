import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logOutUser, onBoarding } from "../redux/slices/admin";
import { tokenSessionTimeout } from "./jwtTokenMiddleware";
import { ErrorMsg } from "../components/ErrorMsg";
import ApiService from "../APIs/api";

export const ProtectedRoute = ({ element }: { element: JSX.Element }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state: any) => state.user.userLogin?.token || "");

  useEffect(() => {
    if (!token) {
      dispatch(logOutUser({}));
      dispatch(onBoarding({}));
      navigate("/", { replace: true });
      ErrorMsg("Token not found, Please login again");
      return;
    }
  }, [navigate, token]);

  if (token) {
    const validateToken = tokenSessionTimeout(token);

    if (!validateToken) {
      dispatch(logOutUser({}));
      dispatch(onBoarding({}));
      navigate("/", { replace: true });
    } else {
      return token ? element : null;
    }
  }

  return null;

  // Render the protected element only if the token exists
};

export const checkloginAuthentication = async (token: string) => {
  if (!token) {
    return;
  }
  const validateToken: any = await tokenSessionTimeout(token);
  if (!validateToken) {
    return;
  }

  await ApiService.setToken(token);

  // API call to get profile status
  try {
    const response: any = await ApiService.get(`/auth/profileStatus`);
    if (response.status === 200) {
      const profileStatus = response.data.result.profileStatus;
      localStorage.setItem("profileStatus", profileStatus);
      return { success: true, profileStatus };
    } else {
      return { success: false, profileStatus: null };
    }
  } catch (error) {
    ErrorMsg("Invalid Token, Please login again.");

    console.error("Error during profileStatus API call", error);
    return { success: false, profileStatus: null };
  }
};
