import {
  Box,
  Divider,
  Grid,
  Stack,
  TablePagination,
  TextField,
} from "@mui/material";
import {
  Typography14,
  Typography18,
} from "../../components/Typography/Typography";
import DoneIcon from "@mui/icons-material/Done";

import { useState, useEffect } from "react";
import React from "react";
import { LoadingButton } from "@mui/lab";
import ApiService from "../../APIs/api";
import { useSelector } from "react-redux";

const AddIndustry = (props: any) => {
  const token = useSelector((state: any) => state.user.userLogin.token);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [Industry, setIndustry] = useState("");
  const [IndustryList, setIndustryList] = useState<any>([]);
  const [NoIndustryFound, setNoIndustryFound] = useState(false);
  const [IndustryAddSuccessMsg, setIndustryAddSuccessMsg] = useState(false);
  const [verifyLoading, setverifyLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleonClickAddIndustry = async (e: any) => {
    e.preventDefault();
    setverifyLoading(true);
    let data = new FormData(e.currentTarget);
    let body = {
      industryName: data.get("industryName"),
    };
    await ApiService.setToken(token);
    const responseData: any = await ApiService.post("admin/addIndustry", body);
    if (responseData.status === 200) {
      setIndustryAddSuccessMsg(true);
      setverifyLoading(false);
      OnMountedIndustryList();
      setIndustry("");
      setBtnDisabled(true);
    } else {
      setNoIndustryFound(true);
      setverifyLoading(false);
    }
  };
  const OnMountedIndustryList = async () => {
    let params = {
      limit: rowsPerPage,
      page: page + 1,
    };
    await ApiService.setToken(token);
    const responseData: any = await ApiService.get(
      "/admin/getIndustry",
      params
    );
    if (responseData.status === 200) {
      let count = responseData.data.total || 0;
      setTotalCount(count);
      setIndustryList(responseData.data.result);
      setNoIndustryFound(false);
    } else {
      console.log(responseData, "error");
    }
  };

  useEffect(() => {
    OnMountedIndustryList();
  }, []);

  const handleonChangeSetIndustry = (e: any) => {
    setIndustryAddSuccessMsg(false);
    setNoIndustryFound(false);
    setIndustry(e.target.value);
    if (e.target.value !== "") {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };
  return (
    <>
      {" "}
      <Grid container sx={{ mx: 4 }}>
        <Grid xs={6}>
          {" "}
          <Box
            mx={2}
            my={2}
            component={"form"}
            onSubmit={handleonClickAddIndustry}
          >
            <Stack spacing={1.5}>
              <Typography14
                color={"#556486"}
                text={"Select Industry"}
              ></Typography14>
              <TextField
                size="small"
                helperText={`Note: If you add an industry, you can't change and update.`}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "400",
                    fontStyle: "normal",
                    fontSize: 14,
                    lineHeight: "14px",
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 400,
                  },
                }}
                onChange={handleonChangeSetIndustry}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "#919EAB",
                    maxWidth: "25vw",
                  },
                }}
                label={"Enter Your Industry"}
                name="industryName"
                fullWidth
                value={Industry}
              />
            </Stack>
            <Box sx={{ py: 3 }}>
              <Stack display={"flex"} direction={"row"} spacing={2}>
                <LoadingButton
                  size="small"
                  disabled={btnDisabled}
                  sx={{
                    px: 4,
                    borderRadius: "8px",
                    fontSize: "14",
                    fontFamily: "Public Sans",
                    gap: "8px",
                    backgroundColor: btnDisabled
                      ? "rgba(145, 158, 171, 0.24)"
                      : "#303981",
                    textTransform: "none",
                    color: btnDisabled ? "grey" : "white",
                    "&:hover": {
                      backgroundColor: btnDisabled
                        ? "rgba(145, 158, 171, 0.24)"
                        : "#303981",
                    },
                  }}
                  loading={verifyLoading}
                  type="submit"
                >
                  Add
                </LoadingButton>

                {IndustryAddSuccessMsg ? (
                  <Box>
                    <Stack
                      display={"flex"}
                      direction={"row"}
                      spacing={0.5}
                      mt={0.6}
                    >
                      <DoneIcon
                        style={{ fontSize: "20px", color: "#36B37E" }}
                      />{" "}
                      <Typography14
                        fontWeight={600}
                        color={"#36B37E"}
                        text={"Industry added successfully !"}
                      >
                        {" "}
                      </Typography14>
                    </Stack>
                  </Box>
                ) : (
                  ""
                )}
                {NoIndustryFound ? (
                  <Box>
                    <Stack
                      display={"flex"}
                      direction={"row"}
                      spacing={0.5}
                      mt={0.6}
                    >
                      <Typography14
                        fontWeight={600}
                        color={"red"}
                        text={"Something went Wrong, Please try again !"}
                      >
                        {" "}
                      </Typography14>
                    </Stack>
                  </Box>
                ) : (
                  ""
                )}
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Grid>
          <Box
            boxShadow={
              "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 4px 0px rgba(145, 158, 171, 0.20)"
            }
            mx={2}
            my={3}
            sx={{
              width: "30vw",
              borderRadius: "8px",
              backgroundColor: "white",
            }}
          >
            <Box
              sx={{
                backgroundColor: "var(--background-light-neutral, #F4F6F8)",
                px: 4,
                py: 3,
              }}
            >
              <Typography18
                color={"#556486"}
                text={"Industries"}
              ></Typography18>
            </Box>
            <Box>
              <Stack sx={{ px: 4, py: 2 }} spacing={3}>
                {IndustryList.length > 0 ? (
                  IndustryList.map((item: any, index: any) => (
                    <Typography14
                      text={item ? item.industryName : "N/A"}
                    ></Typography14>
                  ))
                ) : (
                  <Typography14
                    sx={{ textAlign: "center" }}
                    text={"No Industry Found !"}
                    color={"dark"}
                  ></Typography14>
                )}
              </Stack>
              <Divider></Divider>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"end"}
                px={0}
                mx={0}
              >
                <TablePagination
                  component="div"
                  count={totalCount}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Stack>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AddIndustry;
