import AdminLoginSvg from "../../assets/illustration_maintenance.svg";
import Grid from "@mui/material/Unstable_Grid2";
import SimpleLoader from "../../components/Loader";
import {
  Container,
  Typography,
  Box,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import ResendOtpIcon from "../../assets/ic_refresh.svg";
import { useSelector } from "react-redux";
import { login, loginUser } from "../../redux/slices/admin";
import { useDispatch } from "react-redux";
import ApiService from "../../APIs/api";
import { ErrorMsg } from "../../components/ErrorMsg";
import { SuccessMsg } from "../../components/SuccessMsg";
import PageNotFound from "../404/PageNotFound";

export default function TwoStepVerification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userName = useSelector((state: any) =>
    state.user.userData ? state.user.userData.username : ""
  );
  const [username, setusername] = useState("");
  const [OTPErrorMsg, setOTPErrorMsg] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [open, setopen] = useState(false);
  const [ResendOTPBtnDisabled, setResendOTPBtnDisabled] = useState(false);

  const handleonChangeUserName = (e: any) => {
    setOTPErrorMsg(false);
    if (e.target.value !== "") {
      setusername(e.target.value);
    } else {
      setusername("");
    }
  };

  const handleFormSubmitLogin = async (e: any) => {
    setopen(true);
    e.preventDefault();
    let data = new FormData(e.currentTarget);
    let body = {
      username: userName,
      otp: data.get("OTP"),
    };
    const responseData: any = await ApiService.post("/auth/verifylogin", body);
    if (responseData.status === 200) {
      setopen(false);
      dispatch(login(true));
      dispatch(loginUser(responseData.data.result));
      localStorage.setItem("Token", responseData.data.result.token);
      toast("SuccessFully Login", {
        style: {
          color: "green",
          fontFamily: "Public Sans",
          fontWeight: 700,
        },
      });
      navigate("/app/dashboard");
    } else {
      setopen(false);
      setOTPErrorMsg(true);
    }
  };

  const resendOTPTimeSet = async () => {
    setResendOTPBtnDisabled(true);
    setSeconds(30);
    const responseData: any = await ApiService.post("/auth/resendverifycode", {
      username: userName,
    });
    if (responseData.status === 200) {
      SuccessMsg("OTP resent successfully!");
    } else {
      ErrorMsg("Something went wrong, Please try again !");
    }
  };
  const resendOTPOnClick = () => {
    setTimeout(() => {
      if (seconds === 0) {
        setResendOTPBtnDisabled(false);
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
  };

  useEffect(() => {
    resendOTPOnClick();
  }, [seconds]);
  const location = useLocation();
  return (
    <>
      {location.state?.fromLogin ? (
        <Box margin={2} sx={{ flexGrow: 1 }}>
          <Container>
            <Box
              margin={4}
              justifyContent={"center"}
              sx={{ display: "flex", flexDirection: "column" }}
              alignItems={"center"}
            >
              <Grid container spacing={{ xs: 5, md: 9, lg: 13 }}>
                <Grid>
                  <Box
                    boxShadow={
                      "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 4px 0px rgba(145, 158, 171, 0.20)"
                    }
                    sx={{
                      borderRadius: 4,
                      px: 6,
                      pt: 4,
                      pb: 1,
                      marginTop: 15,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      maxWidth: "340px",
                    }}
                  >
                    <Stack spacing={0.5} sx={{ mb: 3, position: "relative" }}>
                      <Typography
                        variant="h6"
                        component="div"
                        fontSize={18}
                        fontWeight={700}
                        fontFamily={"Public Sans"}
                        lineHeight={"30px"}
                        color={"#334A67"}
                      >
                        Two-step Verification
                      </Typography>
                      <Typography
                        component="div"
                        fontSize={12}
                        fontWeight={500}
                        fontFamily={"Public Sans"}
                        color={"#556486"}
                        letterSpacing={0.2}
                      >
                        Please enter your code for Two-step verification to
                        access
                        <br /> your account with username{" "}
                        <b style={{ fontSize: 13 }}>
                          {" "}
                          {userName ? userName : ""}
                        </b>
                      </Typography>

                      <Box
                        component="form"
                        onSubmit={handleFormSubmitLogin}
                        sx={{ py: 1 }}
                      >
                        <TextField
                          margin="normal"
                          error={OTPErrorMsg}
                          className={"hide-spin-buttons"}
                          autoFocus
                          helperText={
                            OTPErrorMsg
                              ? "Invalid OTP, Please try again !"
                              : "Enter a six-digit numeric OTP"
                          }
                          sx={{
                            "& .MuiFormLabel-root": {
                              color: "#919EAB",
                              fontFamily: "Public Sans",
                              fontWeight: "500",
                              fontSize: 14,
                            },
                            "& .MuiFormHelperText-root": {
                              fontFamily: "Public Sans",
                              fontWeight: 500,
                            },
                          }}
                          fullWidth
                          name="OTP"
                          label="Enter Your OTP"
                          id="OTP"
                          type="text"
                          value={username && username.replace(/\D/g, "")}
                          onChange={handleonChangeUserName}
                          size="small"
                          InputProps={{
                            inputProps: {
                              maxLength: 6,
                              inputMode: "numeric",
                              pattern: "[0-9]*", 
                            },
                            style: {
                              borderRadius: "8px",
                              height: "40px",
                              borderColor: "#919EAB",
                            },
                          }}
                        />
                        <Stack
                          display={"flex"}
                          direction={"row"}
                          justifyContent={"center"}
                          spacing={3}
                          sx={{ mt: 2.5, mb: 2.5 }}
                        >
                          <Button
                            onClick={resendOTPTimeSet}
                            disabled={ResendOTPBtnDisabled}
                            style={{
                              color: ResendOTPBtnDisabled ? "grey" : "green",
                              textTransform: "none",
                              background: ResendOTPBtnDisabled
                                ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                                : "var(--transparent-primary-16, rgba(0, 171, 85, 0.16))",
                              borderRadius: "8px",
                              justifyContent: "center",
                              alignItems: "center",
                              minWidth: "155px",
                              fontWeight: "600",
                              fontFamily: "Public Sans",
                            }}
                            size="small"
                          >
                            <img
                              src={ResendOtpIcon}
                              style={{ marginRight: 2 }}
                              alt=""
                            />{" "}
                            Resend Code
                          </Button>
                          <Button
                            disabled={
                              username &&
                              username.trim().replace(/\D/g, "").length === 6
                                ? false
                                : true
                            }
                            style={{
                              fontWeight: 600,
                              color:
                                username.replace(/\D/g, "").trim().length !== 6
                                  ? "grey"
                                  : "white",
                              textTransform: "none",
                              background:
                                username.replace(/\D/g, "").trim().length !== 6
                                  ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                                  : "#303981",
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "4px",
                              minWidth: "155px",
                              fontFamily: "Public Sans",
                            }}
                            size="small"
                            type="submit"
                          >
                            Verify
                          </Button>
                        </Stack>
                        {seconds > 0 ? (
                          <Typography
                            component="div"
                            fontSize={12}
                            marginTop={1}
                            fontWeight={500}
                            fontFamily={"Public Sans"}
                            color={"#556486"}
                          >
                            Please wait{" "}
                            <b
                              style={{
                                fontSize: 13,
                                color: "black",
                                margin: 1,
                              }}
                            >
                              {seconds}
                            </b>{" "}
                            seconds for Resend OTP
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Stack>
                    <SimpleLoader props={open} />
                  </Box>
                </Grid>
                <Grid>
                  <Box mt={6}>
                    <img
                      src={AdminLoginSvg}
                      style={{
                        marginTop: "60px",
                        maxHeight: "350px",
                        maxWidth: "400px",
                        alignItems: "center",
                      }}
                      alt=""
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      ) : (
        <>
          <PageNotFound />
        </>
      )}
    </>
  );
}
