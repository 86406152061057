import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import { Router } from "./routes";
import { Toaster } from "react-hot-toast";
import LoginPage from "./pages/auth/LoginPage";
import TwoStepVerification from "./pages/auth/TwoStepAuthentication";
import { useProSidebar } from "react-pro-sidebar";
import ForgotPassword from "./pages/auth/ForgotPassword";
import { AppBar, Box } from "@mui/material";
import Sidebars from "./components/Sidebar/Sidebars";
import Footer from "./components/Footer";
import PageNotFound from "./pages/404/PageNotFound";
import { ProtectedRoute } from "./routes/authentication";
import { Dashboard } from "@mui/icons-material";
import {
  Activities,
  AffliateMain,
  BusinessMain,
  BusinessNetworkTools,
  BusinessReferenceNumber,
  CouponManagement,
  CreateAffliateMain,
  CreateCoupon,
  DurationMain,
  ExportReport,
  InviteLogs,
  Main,
  OnBoardingTools,
  PaymentMain,
  PaymentManagement,
  Revenue,
  SalesMain,
  SetPartnerCommission,
  ViewOrder,
} from "./routes/elements";
import ReportsExport from "./pages/Reports/ReportsExport";
import CreateUser from "./pages/UserManagement/CreateUser";
import ViewUsers from "./pages/UserManagement/ViewUsers";
import {
  DashboardRouter,
  ForgotPassowrdRouter,
  LoginRouter,
  TwoStepVerificationRouter,
} from "./hooks/customHooks";

export default function App() {
  const collapsed = useProSidebar().collapsed;

  return (
    <Routes>
      <Route path="/" element={LoginRouter()} />
      <Route path="/verification" element={TwoStepVerificationRouter()} />
      <Route path="/forgotpassword" element={ForgotPassowrdRouter()} />
      {/* Dashboard Route with Nested Protected Routes */}
      <Route path="/app" element={DashboardRouter()}>
        <Route
          path="onboarding"
          element={<ProtectedRoute element={<OnBoardingTools />} />}
        />{" "}
        <Route
          path="userlist"
          element={<ProtectedRoute element={<Main />} />}
        />{" "}
        <Route
          path="reference"
          element={<ProtectedRoute element={<BusinessReferenceNumber />} />}
        />{" "}
        <Route
          path="businessnetwork"
          element={<ProtectedRoute element={<BusinessNetworkTools />} />}
        />{" "}
        <Route
          path="users"
          element={<ProtectedRoute element={<ViewUsers />} />}
        />{" "}
        <Route
          path="createuser"
          element={<ProtectedRoute element={<CreateUser />} />}
        />
        <Route
          path="dashboard"
          element={<ProtectedRoute element={<Dashboard />} />}
        />
        <Route
          path="fees"
          element={<ProtectedRoute element={<PaymentMain />} />}
        />
        <Route
          path="duration"
          element={<ProtectedRoute element={<DurationMain />} />}
        />
        <Route
          path="commission"
          element={<ProtectedRoute element={<SetPartnerCommission />} />}
        />
        <Route
          path="invite"
          element={<ProtectedRoute element={<InviteLogs />} />}
        />
        <Route
          path="coupons"
          element={<ProtectedRoute element={<CouponManagement />} />}
        />
        <Route
          path="createcoupon"
          element={<ProtectedRoute element={<CreateCoupon />} />}
        />
        <Route
          path="business"
          element={<ProtectedRoute element={<BusinessMain />} />}
        />
        <Route
          path="affliate"
          element={<ProtectedRoute element={<AffliateMain />} />}
        />
        <Route
          path="addaffliate"
          element={<ProtectedRoute element={<CreateAffliateMain />} />}
        />
        <Route
          path="sales"
          element={<ProtectedRoute element={<SalesMain />} />}
        />
        <Route
          path="payment/list"
          element={<ProtectedRoute element={<PaymentManagement />} />}
        />
        <Route
          path="payment/order"
          element={<ProtectedRoute element={<ViewOrder />} />}
        />
        <Route
          path="payment/revenue"
          element={<ProtectedRoute element={<Revenue />} />}
        />
        <Route
          path="activities"
          element={<ProtectedRoute element={<Activities />} />}
        />
        <Route
          path="reports"
          element={<ProtectedRoute element={<ReportsExport />} />}
        />
        <Route
          path="downloadreports"
          element={<ProtectedRoute element={<ExportReport />} />}
        />
      </Route>
      <Route path="/app/" element={<PageNotFound />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
