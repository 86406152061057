import React, { useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableContainer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import NoRecordFound from "../../components/NoRecordFound/NoRecordFound";
import { formatDateString } from "../../utils/Constant";
import CircularTableLoader from "../../components/Loader/CircularTableLoader";
import { Typography12 } from "../../components/Typography/Typography";

export default function TableList(props: any) {
  const batchList = props ? props.props : [];
  const [openDialog, setOpenDialog] = useState(false); // Dialog state
  const [currentItem, setCurrentItem] = useState<any>(null); // Store selected item

  const handleOpenDialog = (item: any) => {
    setCurrentItem(item); // Set the item for request/response
    setOpenDialog(true); // Open the dialog
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#F7F9FB" }}>
              {/* <TableCell>
                <Typography fontSize={13} fontWeight={700} color={"grey"}>
                  User Details
                </Typography>
              </TableCell> */}

              <TableCell>
                <Typography fontSize={13} fontWeight={700} color={"grey"}>
                  Activity
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={13} fontWeight={700} color={"grey"}>
                  Bank Details
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={13} fontWeight={700} color={"grey"}>
                  isBatch
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={13} fontWeight={700} color={"grey"}>
                  Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={13} fontWeight={700} color={"grey"}>
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {batchList.length > 0 &&
              batchList.map((item: any, index: any) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Box>
                        <Stack direction={"row"} spacing={0.5}>
                          <Typography
                            fontSize={13}
                            color={"#F58336"}
                            fontWeight={600}
                          >
                            {item && item.activity}
                          </Typography>
                        </Stack>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Stack direction={"row"} spacing={1}>
                        <Typography
                          fontSize={13}
                          color={"#212B36"}
                          fontWeight={700}
                        >
                          {item ? item.bank.name : "N/A"}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography
                        fontSize={11}
                        color={"#212B36"}
                        fontWeight={500}
                      >
                        {item.isBatch ? "Yes" : "No"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        fontSize={11}
                        color={"#212B36"}
                        fontWeight={500}
                      >
                        {formatDateString(item.createdAt) || "N/A"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        sx={{
                          px: 1,
                          py: 1,
                          borderRadius: "8px",
                          backgroundColor: "#303981",
                          textTransform: "none",
                          "&:hover": { backgroundColor: "#303981" },
                        }}
                        onClick={() => handleOpenDialog(item)} // Open dialog on click
                      >
                        <Typography12
                          color={"white"}
                          text={"Request / Response"}
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        {props.open ? (
          <CircularTableLoader />
        ) : (
          <>
            {batchList.length <= 0 ? (
              <NoRecordFound text1={"Account"} text2={"Account"} />
            ) : (
              ""
            )}
          </>
        )}
      </TableContainer>

      {/* Dialog for Request/Response */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: {
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
            backgroundColor: "#FFFFFF",
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#f5f5f5",
            borderBottom: "1px solid #e0e0e0",
            fontWeight: 700,
            fontSize: "18px",
            padding: "16px",
          }}
        >
          Request / Response Details
        </DialogTitle>
        <DialogContent
          sx={{
            padding: "24px",
            backgroundColor: "#F9FAFB",
            maxHeight: "500px",
            overflow: "auto",
          }}
        >
          <Stack direction="row" spacing={2}>
            {/* Request Box */}
            <Box
              sx={{
                width: "50%",
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                padding: "16px",
                borderRadius: "8px",
                border: "1px solid #e0e0e0",
              }}
            >
              <Typography
                variant="h6"
                fontWeight={700}
                gutterBottom
                sx={{ color: "#303981" }}
              >
                Request
              </Typography>
              <pre
                style={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  fontSize: "13px",
                  color: "#333",
                }}
              >
                {JSON.stringify(currentItem?.request, null, 2) ||
                  "No Request Data"}
              </pre>
            </Box>

            {/* Response Box */}
            <Box
              sx={{
                width: "50%",
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                padding: "16px",
                borderRadius: "8px",
                border: "1px solid #e0e0e0",
              }}
            >
              <Typography
                variant="h6"
                fontWeight={700}
                gutterBottom
                sx={{ color: "#303981" }}
              >
                Response
              </Typography>
              <pre
                style={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  fontSize: "13px",
                  color: "#333",
                }}
              >
                {JSON.stringify(currentItem?.response, null, 2) ||
                  "No Response Data"}
              </pre>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "16px",
            backgroundColor: "#f5f5f5",
            borderTop: "1px solid #e0e0e0",
          }}
        >
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", boxShadow: "none" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
