import { Box, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { onBoardingEnrollmentFees } from "../../redux/slices/admin";
import { useDispatch } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import {
  Typography14,
  Typography20,
} from "../../components/Typography/Typography";
import { LoadingButton } from "@mui/lab";
import ApiService from "../../APIs/api";
import CircularTableLoader from "../../components/Loader/CircularTableLoader";

const OnBoardingFees = (props: any) => {
  const token = props.props;
  const [errorMsg, seterrorMsg] = useState(false);
  const getData = useSelector((state: any) => state.user.user.enrollmentFees);
  const dispatch = useDispatch();
  const [verifyLoading, setverifyLoading] = useState(false);
  const [LimitFeesErrorMsg, setLimitFeesErrorMsg] = useState("");
  // setState for Boarding fees
  const [BoardingFees, setBoardingFees] = useState<any>({
    enrollmentFees: "",
  });
  const [LastBoardingFees, setLastBoardingFees] = useState<any>({
    enrollmentFees: "",
  });

  //functionality for enabled and disabled btn state
  const [btnDisabled, setbtnDisabled] = useState(true);
  // success msg for Update Enrollement fees
  const [UpdateSuccessMsg, setUpdateSuccessMsg] = useState(false);
  useEffect(() => {
    let value = {
      enrollmentFees: getData,
    };
    setBoardingFees(value);
    setLastBoardingFees(value);
  }, [getData]);

  // on Change function for arrange data
  const arrangeBoardingfees = (event: any) => {
    setbtnDisabled(false);
    setUpdateSuccessMsg(false);
    const { name, value } = event.target;
    setBoardingFees((preve: any) => {
      return {
        ...preve,
        [name]: value,
      };
    });
  };
  const handleEnrollmentFeesForm = async (e: any) => {
    e.preventDefault();
    seterrorMsg(false);
    setLimitFeesErrorMsg("");
    setverifyLoading(true);
    setbtnDisabled(true);
    await ApiService.setToken(token);
    const responseData: any = await ApiService.post(
      "/admin/setLimits",
      BoardingFees
    );
    if (responseData.status === 200) {
      dispatch(onBoardingEnrollmentFees(responseData.data.result));
      setbtnDisabled(true);
      setUpdateSuccessMsg(true);
      setverifyLoading(false);
    } else {
      seterrorMsg(true);
      setLimitFeesErrorMsg(
        responseData.response.data.error
          ? responseData.response.data.error
          : responseData.message
      );
      setbtnDisabled(false);
      setverifyLoading(false);
    }
  };
  return (
    <Box
      boxShadow={
        "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 4px 0px rgba(145, 158, 171, 0.20)"
      }
      mx={2}
      my={1.5}
      sx={{ borderRadius: "8px", backgroundColor: "white" }}
    >
      {props.open ? (
        <CircularTableLoader />
      ) : (
        <Stack mx={4} my={2.5}>
          <Typography14
            color={"#556486"}
            fontWeight={500}
            text={"Set Enrollment fee for new users"}
          ></Typography14>
          <Box component={"form"} onSubmit={handleEnrollmentFeesForm}>
            <Stack direction={"row"} display={"flex"} spacing={5} mt={3}>
              <TextField
                margin="normal"
                helperText={`Current Enrollment fees  is ${LastBoardingFees.enrollmentFees}`}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: 14,
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 500,
                  },
                }}
                name="enrollmentFees"
                label="Enrollment Fees"
                value={BoardingFees.enrollmentFees}
                onChange={arrangeBoardingfees}
                id="enrollmentFees"
                type="text"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    height: "40px",
                    borderColor: "#919EAB",
                    minWidth: "25vw",
                  },
                }}
              />
            </Stack>
            <Stack
              display={"flex"}
              direction={"row"}
              sx={{ mt: 4 }}
              spacing={2}
            >
              <LoadingButton
                size="small"
                disabled={btnDisabled}
                sx={{
                  borderRadius: "8px",
                  fontSize: "14",
                  fontFamily: "Public Sans",
                  gap: "8px",
                  backgroundColor: btnDisabled
                    ? "rgba(145, 158, 171, 0.24)"
                    : "#303981",
                  minWidth: "25vw",
                  textTransform: "none",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#303981",
                  },
                }}
                loading={verifyLoading}
                type="submit"
              >
                Update
              </LoadingButton>

              {UpdateSuccessMsg ? (
                <div>
                  <Stack
                    display={"flex"}
                    direction={"row"}
                    spacing={0.5}
                    mt={0.6}
                  >
                    <DoneIcon style={{ fontSize: "20px", color: "#36B37E" }} />{" "}
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      fontFamily={"Public Sans"}
                      color={"#36B37E"}
                    >
                      {" "}
                      Enrollement Fees updated Successfully
                    </Typography>
                  </Stack>
                </div>
              ) : (
                ""
              )}
              {errorMsg ? (
                <div>
                  <Stack
                    display={"flex"}
                    direction={"row"}
                    spacing={0.5}
                    mt={0.6}
                  >
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      fontFamily={"Public Sans"}
                      color={"red"}
                    >
                      {" "}
                      {LimitFeesErrorMsg}
                    </Typography>
                  </Stack>
                </div>
              ) : (
                ""
              )}
            </Stack>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default OnBoardingFees;
