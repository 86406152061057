import { Box, Stack, TextField, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { onBoardingUserLimits } from "../../redux/slices/admin";
import { LoadingButton } from "@mui/lab";
import "../../App.css";
import { Typography14 } from "../../components/Typography/Typography";
import ApiService from "../../APIs/api";
import CircularTableLoader from "../../components/Loader/CircularTableLoader";

const OnBoardingLimits = (props: any) => {
  // action UseDispatch for redux
  const dispatch = useDispatch();
  // token get from props driling
  const token = props.props;
  // set Limits State through redux store
  const getData = useSelector((state: any) => state.user.user);
  const [btnDisabled, setbtnDisabled] = useState(true);
  const [UpdateSuccessMsg, setUpdateSuccessMsg] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState(false);
  const [ErrorMsgForLimits, setErrorMsgForLimits] = useState("");
  // Loader State for Loading Btn
  const [verifyLoading, setVerifyLoading] = useState(false);

  // set UserLimits using onChange Event
  const [UserLimits, setUserLimits] = useState<any>({
    gstLimit: "",
    panLimit: "",
    aadharLimit: "",
    cin: "",
  });
  const [UserLastLimits, setUserLastLimits] = useState<any>({
    gstLimit: "",
    panLimit: "",
    aadharLimit: "",
    cin: "",
  });

  // arrange userLimits state through onchange event
  const arrangeUserLimits = (event: any) => {
    setUpdateSuccessMsg(false);
    setbtnDisabled(false);
    const { name, value } = event.target;
    setUserLimits((preve: any) => {
      return {
        ...preve,
        [name]: value,
      };
    });
  };

  // set data on mounted using useEffect Hooks using redux store
  useEffect(() => {
    if (getData) {
      setUserLimits({
        aadharLimit: getData.aadharLimit,
        gstLimit: getData.gstLimit,
        panLimit: getData.panLimit,
        cin: getData.cin,
      });
      setUserLastLimits({
        aadharLimit: getData.aadharLimit,
        gstLimit: getData.gstLimit,
        panLimit: getData.panLimit,
        cin: getData.cin,
      });
    }
    // getData passes into array for update and redener page every time
  }, [getData]);

  // Update userLimits through formSubmit event
  const handleUserLimitsForm = async (e: any) => {
    e.preventDefault();
    setVerifyLoading(true);
    setErrorMsg(true);
    setbtnDisabled(true);

    await ApiService.setToken(token);
    const responseData: any = await ApiService.post(
      "/admin/setLimits",
      UserLimits
    );
    if (responseData.status === 200) {
      // set response object in store using onBoardingLimits
      dispatch(onBoardingUserLimits(responseData.data.result));
      setVerifyLoading(false);
      setbtnDisabled(true);
      // set Success Msg
      setUpdateSuccessMsg(true);
    } else {
      setErrorMsg(true);

      setErrorMsgForLimits(
        responseData.response.data.error
          ? responseData.response.data.error
          : responseData.message
      );
      setVerifyLoading(false);
      setbtnDisabled(false);
    }
  };
  return (
    <Box
      boxShadow={
        "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 4px 0px rgba(145, 158, 171, 0.20)"
      }
      mx={2}
      // my={1.5}
      sx={{ borderRadius: "16px", backgroundColor: "white" }}
    >
      {props.open ? (
        <CircularTableLoader />
      ) : (
        <Stack mx={4} my={2.5}>
          <Typography14
            color={"#556486"}
            fontWeight={500}
            text={"Set Limits for new users"}
          ></Typography14>

          <Box component={"form"} onSubmit={handleUserLimitsForm}>
            <Stack direction={"row"} display={"flex"} spacing={5} mt={3}>
              <TextField
                margin="normal"
                helperText={`Current GST limit is ${UserLastLimits.gstLimit}`}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: 14,
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 500,
                    color: "#637381",
                    fontSize: 11,
                  },
                }}
                value={UserLimits.gstLimit}
                name="gstLimit"
                label="GST Limit"
                id="gstLimit"
                type="number"
                size="small"
                className={"hide-spin-buttons"}
                onChange={arrangeUserLimits}
                InputProps={{
                  inputProps: { max: 10 },
                  style: {
                    borderRadius: "8px",
                    height: "40px",
                    borderColor: "#919EAB",
                    minWidth: "25vw",
                  },
                }}
              />
              <TextField
                margin="normal"
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: 14,
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 500,
                    color: "#637381",
                    fontSize: 11,
                  },
                }}
                name="panLimit"
                label="PAN limit"
                value={UserLimits.panLimit}
                helperText={`Current PAN limit is ${UserLastLimits.panLimit}`}
                onChange={arrangeUserLimits}
                id="panLimit"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    height: "40px",
                    borderColor: "#919EAB",
                    minWidth: "25vw",
                  },
                }}
              />
            </Stack>

            <Stack direction={"row"} display={"flex"} spacing={5} mt={2.5}>
              <TextField
                margin="normal"
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: 14,
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 500,
                    color: "#637381",
                    fontSize: 11,
                  },
                }}
                name="aadharLimit"
                onChange={arrangeUserLimits}
                helperText={`Current Aadhar limit is ${UserLastLimits.aadharLimit}`}
                value={UserLimits.aadharLimit}
                label="Aadhar Limit"
                id="aadharLimit"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    height: "40px",
                    borderColor: "#919EAB",
                    minWidth: "25vw",
                  },
                }}
              />
              <TextField
                margin="normal"
                value={UserLimits.cin}
                helperText={`Current CIN limit is ${UserLastLimits.cin}`}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: 14,
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 500,
                    fontSize: 11,
                    color: "#637381",
                  },
                }}
                name="cin"
                label="CIN limit"
                id="cin"
                onChange={arrangeUserLimits}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    height: "40px",
                    borderColor: "#919EAB",
                    minWidth: "25vw",
                  },
                }}
              />
            </Stack>
            <Stack
              display={"flex"}
              direction={"row"}
              sx={{ mt: 4 }}
              spacing={2}
            >
              <LoadingButton
                size="small"
                disabled={btnDisabled}
                sx={{
                  borderRadius: "8px",
                  fontSize: "14",
                  fontFamily: "Public Sans",
                  gap: "8px",
                  backgroundColor: btnDisabled
                    ? "rgba(145, 158, 171, 0.24)"
                    : "#303981",
                  minWidth: "25vw",
                  textTransform: "none",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#303981",
                  },
                }}
                loading={verifyLoading}
                type="submit"
              >
                Update
              </LoadingButton>

              {UpdateSuccessMsg ? (
                <div>
                  <Stack
                    display={"flex"}
                    direction={"row"}
                    spacing={0.5}
                    mt={0.6}
                  >
                    <DoneIcon style={{ fontSize: "20px", color: "#36B37E" }} />{" "}
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      fontFamily={"Public Sans"}
                      color={"#36B37E"}
                    >
                      {" "}
                      Users Limits Updated Successfully
                    </Typography>
                  </Stack>
                </div>
              ) : (
                ""
              )}
              {ErrorMsg ? (
                <div>
                  <Stack
                    display={"flex"}
                    direction={"row"}
                    spacing={0.5}
                    mt={0.6}
                  >
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      fontFamily={"Public Sans"}
                      color={"red"}
                    >
                      {" "}
                      {ErrorMsgForLimits}
                    </Typography>
                  </Stack>
                </div>
              ) : (
                ""
              )}
            </Stack>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default OnBoardingLimits;
